import styled from '@emotion/styled';
import Image from '~/components/publicWebsiteV2/common/Image';

export const HeroImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

export const HeroImageSized = styled(Image)`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;
